<template>
<div>
  usb
</div>
</template>
<script>
export default {
  name: 'TestUsbKey',
  components: {
  },
  props: {
  },
  data() {
    return {
      wsobj: null,
    }
  },
  computed: {
  },
  watch: {
  },
  mounted: function () {
    console.log('test usb key mounted', this.xtx, this.xtx.test)
    this.xtx.test();
    this.xtx.load(this.xtxCallback);
    // this.xtx.heart();
  },
  destroyed: function () {
    this.xtx.unload();
  },
  methods: {
    xtxCallback: function(type, content){
      console.log('usb call back', type, content);
      if(type == "user"){
        let users = [];
        if(content && content.length > 0){
          let cs = content.split('&&&');
          if(cs.length > 0){
            for(let c of cs){
              let us = c.split('||');
              if(us.length == 2){
                users.push({name: us[0], key: us[1]});
              }
            }
          }
        }
        console.log('get user list:', users);
        if(users.length > 0){
          this.wsobj.login(users[0].key, '123456')
        }
      }else if(type == "login"){
        //
      }
    },
  }
}
</script>
<style scoped>

</style>