<template>
  <div class="home-container" :style="{backgroundImage: `url(${img.home.content.bg})`}">
    <div class="home-header">
      <div class="header-side">
        <img :src="img.home.header.left" />
      </div>
      <div class="header-center">
        <img :src="img.home.header.center" />
      </div>
      <div class="header-side">
        <div class="header-user">
          <div class="header-space"></div>
          <div class="header-user-name">
            <img :src="img.home.header.user" />
            欢迎您，{{user.name}}
            <span @click="logout">登出</span>
          </div>
        </div>
        <img :src="img.home.header.right" />
      </div>
    </div>
    <div class="home-content">
      <div class="content-side"></div>
      <div class="content-center">
        <div class="center-side"></div>
        <div class="center-menu">
          <template v-for="(item, idx) in appTypes">
           <div class="menu-item"  :key="idx" @click="switchMenu(item)">
            <img :src="img.home.apps[item.code]" />
            <div class="menu-item-title">{{item.name}}</div>
          </div>
          </template>
        </div>
        <div class="center-side"></div>
      </div>
      <div class="content-side"></div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
    }
  },
  computed: {
    ...mapState('auth', ['token', 'user', 'appTypes']),
  },
  beforeCreate: function(){
  },
  created: function () {
  },
  mounted: function(){
    this.$store.commit('auth/setAppType', '')
  },
  methods: {
    logout: function(){
      this.$store.dispatch('auth/logout').then(res => {
        if(res){
          this.$Message.info('已登出系统');
          let url = '/login';
          // console.log('log out', this.domains)
          // if(this.domains.version == 'new'){
          //   url = '/loginNew';
          // }
          this.$router.replace(url);
        }
      });
    },
    switchMenu: function(item){
      // this.$store.commit('auth/setAppType', item.code)
      this.$router.push(item.url);
    },
  }
}
</script>
<style scoped>
.home-container{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.home-header{
  /* border: solid 1px red; */
  height: 150px;
  flex: none;
  display: flex;
  align-items: flex-end;
}
.header-center{
  width: 640px;
  flex: none;
}
.header-side{
  width: 10px;
  flex: auto;
}
.header-user{
  /* padding-right: 100px;
  text-align: right; */
  /* border: solid 1px red; */
  display: flex;
}
.header-space{
  width: 10px;
  flex: auto;
}
.header-user-name{
  width: 250px;
  flex: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.6;
}
.header-user-name img{
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.header-user-name span{
  /* height: 30px;
  width: 30px; */
  color: #aaa;
  margin-left: 10px;
  cursor: pointer;
}
.home-content{
  /* border: solid 1px red; */
  height: 10px;
  flex: auto;
  display: flex;
}
.content-side{
  /* border: solid 1px red; */
  width: 300px;
  flex: none;
}
.content-center{
  width: 10px;
  flex: auto;
  display: flex;
  flex-direction: column;
}
.center-side{
  /* border: solid 1px red; */
  height: 60px;
  flex: none;
}
.center-menu{
  /* border: solid 1px red; */
  height: 10px;
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  justify-items: center;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.center-menu::-webkit-scrollbar {
  display: none;
}
.menu-item{
  cursor: pointer;
  width: 250px;
  height: 350px;
  margin: 30px 20px;
  color: #FFFFFF;
  position: relative;
}
.menu-item img{
  width: 100%;
  height: 100%;
}
.menu-item-title{
  position: absolute;
  bottom: 32px;
  left: 60px;
  width: 130px;
  height: 35px;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0591FA;

  background: linear-gradient(0deg, #0591FA 0%, #FFFFFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
