<template>
  <div class="home-container">
    <div class="alarm-box">
      <div class="alarm-header">欢迎使用扫码报障功能</div>
      <div class="pole-info">
        <div class="info-item">
          <div class="info-title-spot"></div>
          <div class="info-title-name">基础信息</div>
        </div>
        <div class="info-item">
          <div class="info-item-header">灯杆名称</div>
          <div class="info-item-content">{{poleInfo.name}}</div>
        </div>
        <div class="info-item">
          <div class="info-item-header">灯杆类型</div>
          <div class="info-item-content">{{poleInfo.asset.poleType}}</div>
        </div>
        <div class="info-item">
          <div class="info-item-header">灯臂类型</div>
          <div class="info-item-content">{{poleInfo.asset.armType}}</div>
        </div>
        <div class="info-item">
          <div class="info-item-header">灯杆高度</div>
          <div class="info-item-content">{{poleInfo.asset.height}}</div>
        </div>
        <div class="info-item">
          <div class="info-item-header">安装地址</div>
          <div class="info-item-content">{{poleInfo.address}}</div>
        </div>
        <div class="info-item">
          <div class="info-item-header">安装日期</div>
          <div class="info-item-content">{{poleInfo.buildDate}}</div>
        </div>
      </div>
    </div>
    <div class="alarm-box">
      <div class="pole-info">
        <div class="info-item">
          <div class="info-title-spot"></div>
          <div class="info-title-name">上传照片</div>
        </div>
        <div class="alarm-img-area">
          <div class="showList" v-if="url.length>0">
            <template v-for="(el,inx) in url">
              <div class="showList-box" :key="inx">
                <img ref="img" :src="el" />
              </div>
            </template>
            <div class="upload-box-Small" v-if="url.length<6">
              <img ref="img" :src="img.mochaITOM.uploadImg" class="uploadImgSmall" @click="selectImage" />
            </div>
          </div>
          <div v-else class="upload-box" @click="selectImage">
            <img ref="img" :src="img.mochaITOM.uploadImg" class="uploadImg" />
            <div class="file-open">点击上传故障图片</div>
          </div>
          <input class="file-control" ref="file" type="file" accept=".png,.jpeg,.ipg" @change="fileChanged" />
        </div>
      </div>
    </div>
    <div class="alarm-box">
      <div class="pole-info">
        <div class="info-item">
          <div class="info-title-spot"></div>
          <div class="info-title-name">备注信息</div>
        </div>
        <Input type="textarea" v-model="form.content" maxlength="100" :rows="5" show-word-limit placeholder="请输入故障现象" style="margin-bottom: 10px"></Input>
        <Input type="text" v-model="form.name" maxlength="20" placeholder="请输入称呼" style="margin-bottom: 10px"></Input>
        <Input type="text" v-model="form.mobile" maxlength="20" placeholder="请输入联系方式，用于回访" style="margin-bottom: 10px"></Input>
      </div>

    </div>
    <div class="alarm-upload" v-if="!success">
      <Button type="primary" :loading="loading" long @click="ok">上报故障</Button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'AlarmUpload',
  components: {
  },
  data() {
    return {
      loading: false,
      poleInfo: {
        asset: {},
      },
      url: [],
      success: false,
      form: {
        id: 0,
        content: '',
        file: [],
        name: '', //联系人
        mobile: '', //联系方式，用于回访
      },
    }
  },
  computed: {
    ...mapState('auth', ['token', 'user', 'appTypes']),
  },
  beforeCreate: function () {
  },
  created: function () {
  },
  mounted: function () {
    this.getPoleInfo();
  },
  methods: {
    openx: function () {
      navigator.mediaDevices.getUserMedia({
        video: {
          height: 500,
          width: 500,
        }
      }).then(res => {
        // console.log('get user media', res)
        this.$refs.camera.srcObject = res;
        this.$refs.camera.play();
      }).catch(err => {
        this.$Modal.error(`出错：${err}`);
      })
    },
    capture: function () {
      this.$refs.pic.getContext("2d").drawImage(this.$refs.camera, 0, 0, 500, 500)
    },
    exitx: function () {
      this.$refs.camera.srcObject.getTracks()[0].stop();
    },
    getPoleInfo: function () {
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryUploadAlarm`, { type: this.$route.query.type, code: this.$route.query.key }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'poleInfo', res.data);
      });
    },
    selectImage: function () {
      this.$refs.file.click();
    },
    fileChanged: function (e) {
      let file = e.target.files[0];
      this.url.push(window.webkitURL.createObjectURL(file));
      this.form.file.push(file);
    },
    ok: function () {
      var frm = new FormData();
      for (let index = 0; index < this.form.file.length; index++) {
        frm.append(`file` + index, this.form.file[index]);
      }
      frm.append("id", this.poleInfo.id);
      frm.append("content", this.form.content);
      frm.append("name", this.form.name);
      frm.append("mobile", this.form.mobile);
      this.loading = true;
      this.$axios.request({
        url: `//${this.domains.trans}/station/alarm/SaveUploadAlarm`,
        method: 'post',
        data: frm,
        responseType: 'json',
        myType: 'file'
      })
      // this.$axios.post(`//${this.domains.trans}/station/alarm/SaveUploadAlarm`, frm)
      .then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.success = true;
        this.$Modal.info({ title: `报障成功`, content: '报障成功' });
      });
      // let formData = new FormData();
      // formData.append("file", this.$refs.fileInput.files[0]);
      // formData.append("stationId", this.form.stationId);
      // this.$axios.request({
      //   url: `//${this.domains.trans}/station/config/ImportPoleTemplate`,
      //   method: 'post',
      //   data: formData,
      //   responseType: 'json',
      //   myType: 'file'
      // }).then(res => {
      //   if(res.code == 0){
      //     if(res.data.length > 0){
      //       this.$Modal.info({
      //         title: this.showLang('import.success.part'),
      //         content: res.data.map(p => `行号${p.row}:${p.message}`).join('<br/>')
      //       });
      //     }else{
      //       this.$Modal.info({
      //         title: this.showLang('import.result'),
      //         content: this.showLang('import.success.all'),
      //       });
      //     }
      //     this.showModal = false;
      //     this.$emit('saved', {});
      //     window.eventBus.$emit('poleChanged');
      //   }
      // });
    },
  }
}
</script>
<style scoped>
.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #eff3fb;
  overflow-y: auto;
}
.alarm-box {
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.alarm-header {
  height: 22px;
  flex: none;
  font-size: 22px;
  font-weight: bold;
  color: #021629;
  line-height: 22px;
  text-align: center;
  margin-top: 20px;
}
.info-title-spot {
  width: 5px;
  height: 5px;
  background: #324252;
  border-radius: 50%;
}
.info-title-name {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #324252;
  line-height: 44px;
  margin-left: 5px;
}
.showList-box {
  width: calc(100% / 3 - 10px);
  height: 113px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
  border: 1px solid #8693a0;
}
.showList-box img {
  max-width: 100%;
  max-height: 113px;
  border-radius: 5px;
}
.upload-box-Small {
  width: calc(100% / 3 - 10px);
  height: 113px;
  text-align: center;
  background: #ecf1f7;
  border: 1px solid #8693a0;
  border-radius: 6px;
  float: left;
  margin: 5px;
  line-height: 126px;
}
.uploadImgSmall {
  width: 30px;
  height: 30px;
}
.pole-info {
  flex: none;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 0 25px;
}
.info-item {
  height: 35px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.info-item-header {
  width: 60px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #68737d;
  line-height: 44px;
}
.info-item-content {
  width: calc(100% - 60px);
  height: 35px;
  background: #ecf1f7;
  border: 1px solid #8693a0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #021629;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
}
.alarm-img-area {
  width: 100%;
  min-height: 200px;
}
.upload-box {
  padding-top: 50px;
  text-align: center;
}
.uploadImg {
  width: 40px;
  height: 40px;
}
.file-open {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #68737d;
  line-height: 44px;
}
.media-player {
  width: 100%;
  height: 100%;
}
.file-control {
  display: none;
}
.alarm-upload {
  height: 100px;
  flex: none;
  padding: 10px 30px 0 30px;
}
</style>
